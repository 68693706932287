import {Injectable} from '@angular/core';
import {
  RateCheckMeterModificationType,
  RateCheckRequestV4FlowModel,
  RcRequestObjectType
} from '../../../../_shared/_model/rate-check-request-v4.model';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {PfServiceAddressTableRowModel} from '../../_model/portfolio-service-addresses.model';
import {PortfolioHelperService} from './portfolio-helper.service';
import {PfMetersTableRowModel, TimelineStatus} from '../../_model/portfolio-meters.model';
import {ServiceAddressService} from '../../../../_shared/_services/v4/service-address.service';
import {ButtonActionTypes, ButtonTypes, ZenDialogDataModel} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {MatDialog} from '@angular/material/dialog';
import {
  RcReqPortfolioConfirmDialogComponent
} from '../../../../_shared/_dialogs/rc-req-portfolio-confirm-dialog/rc-req-portfolio-confirm-dialog.component';
import {CommodityType} from '../../../../_shared/_zen-legacy-common/_models/commodity';
import {CommodityValidationV4Model} from '../../../../_shared/_model/commodity-validation-v4.model';
import {ZenRcReqFlowHelperService} from '../../../zen-rate-check-request-v2/_services/zen-rc-req-flow-helper.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioRateCheckService {

  constructor(
    private propV4Svc: ServiceAddressService,
    private zenDialogSvc: ZenDialogMsgService,
    private pfHelpSvc: PortfolioHelperService,
    private rcReqHelpSvc: ZenRcReqFlowHelperService,
    private dialog: MatDialog
  ) {
  }

  createRateCheckByMeterIds(_meters: PfMetersTableRowModel[]) {
    const _isSingleCustomer = new Set(_meters?.map(m => m.customerId)).size === 1;
    const _isSingleCommodity = new Set(_meters?.map(m => m.commodityType)).size === 1;
    const _isInactiveMeters = _meters?.some(m => !m.active);
    const _isSingleState = new Set(_meters?.map(m => m.serviceAddressState)).size === 1;
    const _inEligibleMeters = _meters?.filter(m => {
      const _timelines = m.meterMetrics?.energyPlanTimelines;
      return (_timelines?.length === 1 && _timelines?.[0].timelineStatus === TimelineStatus.UNDEFINED) ||
        (_timelines?.length === 1 && _timelines?.[0].timelineStatus === TimelineStatus.DRAFT);
    });

    if (_inEligibleMeters?.length) {
      this.zenDialogSvc.openErrorDialog(true, 'Ineligible meters found in your selection.');
    } else {

      if (_isSingleCustomer && _isSingleCommodity && !_isInactiveMeters && _isSingleState) {

        let request = {
          customerId: _meters?.[0]?.customerId,
          state: _meters?.[0]?.serviceAddressState,
          commodityType: _meters?.[0]?.commodityType,
          meterChanges: {
            ids: _meters?.map(p => p.id),
            objectType: RcRequestObjectType.METER, // Default METER
            modificationType: RateCheckMeterModificationType.ADD
          }
        } as RateCheckRequestV4FlowModel;

        this.openPortfolioStartRcConfirmDialog(null, request);
      } else {
        this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_METER_CREATE_RATE_CHECK_ERROR);
      }
    }
  }

  createRateCheckByserviceAddressIds(serviceAddresses: PfServiceAddressTableRowModel[]) {
    const _isSingleCustomer = new Set(serviceAddresses?.map(p => p.customerId)).size === 1;
    const _isSingleState = new Set(serviceAddresses?.map(p => p.state)).size === 1;
    const _isInactiveProps = serviceAddresses?.some(p => !p.active);

    if (_isSingleCustomer && _isSingleState && !_isInactiveProps) {
      const ids = serviceAddresses?.map(p => p.id);
      const customerId = serviceAddresses?.[0]?.customerId;

      this.pfHelpSvc.loading = true;
      this.propV4Svc.getAvailableCommodityTypesByIds(customerId, ids)
        .subscribe({
          next: commodityExistCheck => {
            this.pfHelpSvc.loading = false;
            if (commodityExistCheck?.hasGas || commodityExistCheck?.hasElectric) {

              const request = {
                customerId,
                state: serviceAddresses?.[0]?.state,
                commodityType: null,
                meterChanges: {
                  ids,
                  objectType: RcRequestObjectType.SERVICE_ADDRESS, // Default METER
                  modificationType: RateCheckMeterModificationType.ADD
                }
              } as RateCheckRequestV4FlowModel;

              this.openPortfolioStartRcConfirmDialog(commodityExistCheck, request);
            } else {
              // The available commodity API fetch commodities of meters with energy profile.
              // If !commodities.length - then there is no eligible meters available for the selected serviceAddressIds.
              this.zenDialogSvc.openToast(false, 'No eligible meters found.');
            }
          }, error: () => {
            this.pfHelpSvc.loading = false;
            this.zenDialogSvc.openToast(false);
          }
        });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_SERVICE_ADDRESS_CREATE_RATE_CHECK_ERROR);
    }
  }

  /**
   * Used to confirm start RC for both the meter/serviceAddress selections.
   * @param commodityExistCheck - Only available for serviceAddress RC.
   * @param request
   */
  openPortfolioStartRcConfirmDialog(commodityExistCheck: CommodityValidationV4Model, request: RateCheckRequestV4FlowModel) {
    const _title = 'Rate Check Management';
    const _bodyHtml = `<p class="text-md">Are you sure want to create a Rate Check with the selected ${commodityExistCheck ? 'service addresses' : 'meters'}?</p>`;

    let dialogData: ZenDialogDataModel = {
      header: {title: _title, icon: ZenIconsEnum.SETTINGS},
      bodyHtml: _bodyHtml,
      onClose: () => {
        dialogRef.close();
        this.pfHelpSvc.loading = false;
      },
      data: {commodityExistCheck},
      actionButtons: [
        {
          label: 'Cancel', btnType: ButtonTypes.MAT_BUTTON, actionType: ButtonActionTypes.CANCEL,
          command: () => {
            dialogRef.close();
            this.pfHelpSvc.loading = false;
          }
        }, {
          label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON,
          actionType: ButtonActionTypes.SUBMIT, color: 'primary',
          command: (selectedCommodity: CommodityType) => {
            dialogRef.close();

            if (commodityExistCheck) {
              request.commodityType = selectedCommodity;
            }

            this.pfHelpSvc.loading = true; // set to false from pfHelpSvc.reset()
            this.rcReqHelpSvc.createRcByRequest(request, true, null,
              () => this.pfHelpSvc.loading = false);
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(RcReqPortfolioConfirmDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData, autoFocus: false
    });
  }

}
