<div *ngIf="displayedColumns?.length" class="zen-mat-table {{tableConfig?.styleType}} {{styleCls}}"
     [ngClass]="viewType === TableViewTypes.COLLAPSED ? 'collapsed-view' : 'detailed-view'"
     [class.expanded]="expandedElement">

  <div class="zen-mat-table-wrapper" [class.no-border]="tableConfig.hideBorder">
    <!-- Table top menu actions - toggled with filterInput -->
    <app-zen-mat-table-actions *ngIf="(selection?.hasValue() && !tableConfig.hideTopSelectedActions) else filterInput"
                               [actions]="tableConfig.tableMenuOptions"
                               [selection]="selection"></app-zen-mat-table-actions>

    <!-- Filter input -->
    <ng-template #filterInput>
      <!-- Regular input search field -->
      <ng-container *ngIf="searchType === 'input-search'">
        <mat-form-field [hidden]="tableConfig.hideSearch" class="filter-input">
          <mat-label>
            <div class="label-sec" *ngIf="!searchInput.focused && !searchInput.value">
              <i class="material-icons label-icon">search</i>
            </div>
          </mat-label>
          <input matInput #searchInput="matInput" [(ngModel)]="searchValue" (keyup)="applyFilter()">
        </mat-form-field>
      </ng-container>
      <!-- Regular input search field -->
      <ng-container *ngIf="searchType === 'select-search' && selectSearchConfig">
        <div class="select-search-main" [id]="tableConfig.selectSearchMainAnchorTourId" [hidden]="tableConfig.hideSearch">
          <div class="col-2 dropdown-select-wrapper">
            <div class="d-flex align-items-center">
              <i class="material-symbols-rounded search-icon">search</i>
              <mat-form-field class="filter-input dropdown-select">
                <mat-select [formControl]="selectSearchConfig.searchTypeCtrl" placeholder="Search Type" panelClass="panel-min-wd-20">
                  <mat-option *ngFor="let op of selectSearchConfig.selectOptions"
                              [value]="op" (click)="onSearchTypeChange()">{{op.objectLabel}}</mat-option>
                </mat-select>
                <i class="material-symbols-rounded expand-icon" matSuffix>expand_more</i>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field class="filter-input">
            <!-- Date search -->
            <div class="date-filter-wrapper"
                 *ngIf="selectSearchConfig.searchTypeCtrl.value?.dataType === ZenTableSelectOptionDataType.DATE else standardInputField">
              <mat-datepicker-toggle matPrefix [for]="searchDateField"></mat-datepicker-toggle>

              <input matInput [matDatepicker]="searchDateField" readonly (click)="searchDateField.open()"
                     [formControl]="selectSearchConfig.inputCtrl" (dateChange)="searchKeyup()">

              <mat-datepicker-toggle matSuffix (click)="clearInput(); searchKeyup();">
                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker (monthSelected)="handleMonthChange($event, searchDateField)"
                              #searchDateField startView="year"></mat-datepicker>
            </div>
            <!-- Input search -->
            <ng-template #standardInputField>
              <input matInput [formControl]="selectSearchConfig.inputCtrl" (input)="searchKeyup()">
            </ng-template>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-template>

    <div class="mat-table-container" [class.on-loading]="loading">
      <table mat-table [dataSource]="dataSource" matSort matSortStart="desc" [style]="tableConfig?.styles" [ngClass]="{loading}"
             [class.no-multiselect]="tableConfig?.hideMultiselect"
             [class.set-min-ht]="!loading && !tableConfig?.hideMinHeight && dataSource.data?.length === 0 && !tableConfig?.defaultState && !(handleNoSearchData || handleNoSelectSearchData || handleNoDateForAddedFilterChips)"
             multiTemplateDataRows
             [id]="tableConfig?.isDraggableTableId"
             cdkDropList
             [cdkDropListDisabled]="!tableConfig.isDraggable"
             [cdkDropListData]="dataSource"
             (cdkDropListDropped)="onDropReceived($event)"
             (cdkDropListEntered)="dragDroppedEvent($event)">

        <!-- Expand icon without checkbox -->
          <ng-container matColumnDef="expansion" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" (click)="handleRowExpansion(row)" class="only-expand-icon">
              <i class="material-icons large pointer">{{expandedElement && data?.length ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}}</i>
            </td>
          </ng-container>


        <!-- Checkbox Column -->
          <ng-container matColumnDef="select" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="!loading && !tableConfig.rowLockingCondition && multiselectType === 'checkbox'">
                <mat-checkbox class="vertical-align-middle ms-3" (change)="masterToggle($event)"
                              [checked]="selection?.hasValue() && isAllSelected()"
                              [indeterminate]="selection?.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </ng-container>
            </th>
            <td mat-cell *matCellDef="let row">
              <ng-container
                *ngIf="(tableConfig.rowLockingCondition ? tableConfig.rowLockingCondition(row) : false) else showCheckBox">
                <i class="material-symbols-rounded lock-icon">lock</i>
              </ng-container>
              <ng-template #showCheckBox>

                <ng-container *ngIf="multiselectType === 'checkbox'">
                  <mat-checkbox class="master-toggle-checkbox ms-3" (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection?.isSelected(row)"></mat-checkbox>
                </ng-container>

                <ng-container *ngIf="multiselectType === 'radio'">
                  <mat-radio-button (click)="onRadioBtnSelected($event, row)" class="ms-3"
                                    [checked]="selectedRadioVal && selectedRadioVal[tableConfig.primaryKey]===row[tableConfig.primaryKey]">
                  </mat-radio-button>
                </ng-container>

                <ng-container *ngIf="multiselectType === 'swap'">
                  <i class="material-symbols-rounded pointer p-2 color-icon no-bg large"
                     (click)="onSwapBtnClick.emit(row)">west</i>
                </ng-container>

              </ng-template>
            </td>
          </ng-container>

        <ng-container *ngFor="let col of tableConfig.cols">
          <ng-container [matColumnDef]="col.field" [sticky]="col.sticky" [stickyEnd]="col.stickyEnd">
            <ng-container *ngIf="col.type !== 'menu'">
              <ng-container *ngIf="!col.hideSort">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="col.sortColumnName ?? null" [ngClass]="col.align"
                    [class]="col.type" [class.th-editable]="col.editable">
                  <ng-template [ngTemplateOutlet]="colHeader"></ng-template>
                </th>
              </ng-container>
              <ng-container *ngIf="col.hideSort">
                <th mat-header-cell *matHeaderCellDef [ngClass]="col.align" [class.th-editable]="col.editable">
                  <ng-template [ngTemplateOutlet]="colHeader"></ng-template>
                </th>
              </ng-container>
              <ng-template #colHeader>
                <div class="col-header-with-tour-anchor {{col.tourAnchorId}} d-flex align-items-center" [id]="col.tourAnchorId"
                     [popperDisabled]="!col.headerTooltipText" (mouseover)="headerTooltipText=col.headerTooltipText"
                     (mouseout)="headerTooltipText=null" [popper]="headerTooltipTextPopper"
                     popperTrigger="hover" [popperApplyClass]="'max-sm  bg-transparent'" popperPlacement="bottom">
                  <i class="material-icons-outlined header-icon" *ngIf="col.headerColIcon">{{col.headerColIcon}}</i>
                  <span class="header-text" [innerHTML]="col.header"></span>
                </div>
              </ng-template>

            </ng-container>

            <ng-container *ngIf="col.type === 'menu'">
              <th mat-header-cell *matHeaderCellDef="let row" class="th-menu text-center">
                <ng-container *ngIf="tableConfig.download?.enable && dataSource?.data?.length > 0">
                  <!-- Special case for download button -->
                  <i class="material-symbols-rounded pointer font-weight-300" (click)="downloadPage()">download</i>
                </ng-container>
                <ng-container *ngIf="!tableConfig.download?.enable && col.colHeaderAction && col.colHeaderAction.icon">
                  <i class="material-symbols-rounded pointer" (click)="col.colHeaderAction.command(col)">{{col.colHeaderAction.icon}}</i>
                </ng-container>
              </th>
            </ng-container>

            <td mat-cell *matCellDef="let element; let dataIndex = dataIndex;" [class]="col.type"
                [ngClass]="{'td-menu': col.type === 'menu', 'zen-has-left-border': element[col.borderLeftColor], 'td-editable': col.editable, 'td-locked': col.lockedCol && element[col.lockedCol]}"
                (mouseover)="handleTdMouseHover(col, element)" (mouseleave)="handleTdMouseLeave()">
              <ng-container *ngIf="!loading">
                <!-- The below HTML takes the border left color field and puts it as a class AND a style. If it's a hex, the style will apply. If not, the class will apply -->
                <div *ngIf="col.type !== 'menu'"
                     class="{{element[col.borderLeftColor]?'td-status-border zen-left-border-color':''}}"
                     [ngClass]="element[col.borderLeftColor]"
                     [ngStyle]="{borderColor: element[col.borderLeftColor], borderWidth:
                  col.borderLeftWidth || '1rem'}"
                     [style.max-width]="col.textMaxWidth ? 'calc(' + col.textMaxWidth + ' - 3rem)' : null">
                  <div class="col-main-wrapper position-relative"
                       [class.hyperlink-enabled]="!(col.lockedCol && element[col.lockedCol]) && (col.hyperlink || col.handleHyperLinkClick) && hoveredCol === col && hoveredElement === element"
                       [class.center]="col.align === 'center'"
                       [class.right]="col.align === 'right'"
                       [class.w-100]="col?.type === 'procurement-snapshot'">
                    <div class="me-3 position-relative {{col.iconPosition}}" *ngIf="col.iconCol && element[col.iconCol]"
                         [class.pointer]="col.handleIconClick"
                         (click)="col.handleIconClick ? col.handleIconClick(col, element) : null">
                      <i class="material-icons color-icon p-2 {{element[col.iconClsCol]}}">{{element[col.iconCol]}}</i>

                      <div class="suffix-icon-wrapper" *ngIf="col.iconSuffixHtmlCol && element[col.iconSuffixHtmlCol]">
                        <span [innerHTML]="element[col.iconSuffixHtmlCol]"></span>
                      </div>
                    </div>
                    <div class="col-image" *ngIf="col.imageCol">
                      <img [src]="element[col.imageCol] || THUMBNAIL"/>
                    </div>

                    <div *ngIf="col.type === 'procurement-snapshot' && element[col.field]" class="w-100">
                      <app-zen-procurement-snapshot
                        [timelines]="element[col.field].timelines"></app-zen-procurement-snapshot>
                    </div>

                    <div *ngIf="col.type === 'checkbox'">
                      <mat-checkbox (change)="handleCheckboxUpdate.emit({element, col})"
                                    [(ngModel)]="element[col.field]"></mat-checkbox>
                    </div>

                    <div *ngIf="col.type === 'notes'" class="col-main-text flex-column">
                      <div class="main-val td-notes" [innerHTML]="element[col.field]"></div>
                      <div *ngIf="col.popperContent && element[col.field]?.length > 15" class="w-100 text-start pointer"
                           [popper]="col.popperContent"
                           [popperTrigger]="col.popperTrigger || 'hover'"
                           [popperApplyClass]="col.popperApplyClass || null"
                           [popperPlacement]="col.popperPlacement || PopperPlacement.AUTOSTART"
                           [popperHideOnMouseLeave]="col.popperHideOnMouseLeave ? true : false">
                        <span class="m-0 blue-1-color small"
                              (click)="col?.handleHyperLinkClick(col, element)">Show more</span>
                      </div>
                      <!-- Add Icons -->
                      <i *ngIf="col.allowAddNew && !element[col.field]" class="material-icons-round color-icon"
                         (click)="col.handleAddNewClick(col, element)">add</i>
                    </div>

                    <!-- progress-status -->
                    <div *ngIf="col.type === 'progress-status'">
                      <span class="zen-progress-status {{element[col.progressColorCol]}}">{{element[col.field]}}</span>
                    </div>

                    <!-- progress-status -->
                    <div *ngIf="col.type === 'button'">
                      <button mat-raised-button class="sm" [color]="col.btnColor || 'accent'"
                              (click)="col.handleHyperLinkClick(col, element)">{{col?.formatter(col, element)}}</button>
                    </div>

                    <div *ngIf="col.type === 'standard' || col.type === 'text-array' || col.type === 'col-sm' || col.type === 'col-md' || col.type === 'col-lg' || col.type === 'number' || col.type === 'standard-padding'" class="d-flex flex-column position-relative">
                      <div class="col-main-text"
                           [class.justify-content-center]="col.align === 'center'"
                           [class.padding]="col.type === 'standard-padding'">
                        <!-- Add Icons -->
                        <i *ngIf="col.allowLinkIcon && element[col.allowLinkField]" class="material-icons-round color-icon color-icon--small rotate">link</i>

                        <i class="material-icons mat-table-icon status-indicator no-bg"
                           *ngIf="col.statusCol && element[col.statusCol]"
                           [ngClass]="element[col.statusCol]"
                           [class.icon-padding-standard]="col.type === 'standard-padding'">fiber_manual_record</i>

                        <!-- When formatter is available its handled here -->
                        <!-- Adding oncontextmenu="return false;" to disable browser right click option. -->
                        <p #mainValElement *ngIf="(!col.enableUnderLinedTooltip && !col.popperContent && !hoveredTooltipText) else rowTooltipTemp"
                              class="main-val no-tooltip text-md {{element[col?.textClassCol] || ''}} {{col?.colStyleCls || ''}}"
                              [style.max-width]="col.textMaxWidth"
                              (click)="handleHyperLinkClick(col, element); mainValMenuTrigger.closeMenu()"

                              (contextmenu)="col.handleHyperLinkRightClick ? mainValMenuTrigger.openMenu() : false" oncontextmenu="return false;"
                              #mainValMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="contextMainValHyperLinkMenu"

                              (mouseover)="onHoverCheckEllipsis(mainValElement)"
                              [innerHTML]="col.formatter ? col.formatter(col, element) : element[col.field] || (col.whiteSpace ? '' : (element[col.field] === 0 ? 0 : '-'))"></p>

                        <mat-menu #contextMainValHyperLinkMenu="matMenu">
                          <button mat-menu-item (click)="handleHyperLinkClick(col, element)">Open</button>
                          <button mat-menu-item (click)="handleHyperLinkRightClick(col, element)">Open Link in New Tab</button>
                        </mat-menu>

                        <!-- Underscore values with tooltip (Used to show ARR fee) -->
                        <ng-template #rowTooltipTemp>
                          <!-- Adding oncontextmenu="return false;" to disable browser right click option. -->
                          <p class="main-val pointer row-tooltip-temp" #mainValElement [class.text-underline]="col.enableUnderLinedTooltip"
                                [style.max-width]="col.textMaxWidth"
                                [ngClass]="col.textClassCol ? col.textClassCol : 'main-val' + (col.colStyleCls ? ' ' + col.colStyleCls : '')"
                                [popper]="hoveredTooltipText ? hoveredTextTooltipContent : (col.popperContent || tooltipContent)"
                                [popperDisabled]="!(col.popperContent || col.enableUnderLinedTooltip || hoveredTooltipText)"
                                [popperTrigger]="col.popperTrigger || 'hover'"
                                [popperApplyClass]="col.popperApplyClass || null"
                                [popperPlacement]="col.popperPlacement || PopperPlacement.AUTOSTART"
                                [popperPositionFixed]="col.popperPositionFixed || false"
                                [popperCloseOnClickOutside]="true"
                                [popperHideOnMouseLeave]="col.popperHideOnMouseLeave"
                                (popperOnShown)="col?.popperOnShown ? col?.popperOnShown() : null"
                                (mouseover)="handleMouseOver(col, element); onHoverCheckEllipsis(mainValElement, hoveredTextTooltipContent)"
                                (click)="handleHyperLinkClick(col, element)"
                                [innerHTML]="col.formatter ? col.formatter(col, element) : element[col.field] || (col.whiteSpace ? '' : '-')">
                          </p>
                        </ng-template>

                        <!-- Main text +more array popper -->
                        <p *ngIf="col.type === 'text-array' && element[col.fieldPopperArrObj]?.length"
                           class="font-weight-500 ms-2 mt-1 text-sm pointer blue-1-color no-wrap"
                           (click)="showSubTextArray(element, col, element[col.fieldPopperArrObj], subTextArrayPopper); subTextMenuTrigger.closeMenu()"

                           [matMenuTriggerFor]="subTextContextMenu" #subTextMenuTrigger="matMenuTrigger"
                           (contextmenu)="tableConfig.handleSubTextArrayClick ? subTextMenuTrigger.openMenu() : false" oncontextmenu="return false;"

                           [popper]="subTextArrayPopper" [popperPositionFixed]="true"
                           popperTrigger="click" popperApplyClass="xs" popperPlacement="auto"
                            >+{{element[col.fieldPopperArrObj]?.length}} more</p>

                        <!-- Tooltip Popper (right side caret icon) -->
                        <i *ngIf="col.enableTooltip" class="material-symbols-rounded tooltip-icon"
                           [popper]="tooltipContent" [popperDisabled]="!col.enableTooltip"
                           [popperPositionFixed]="true"
                           popperTrigger="click" [popperApplyClass]="col.popperApplyClass || 'sm'"
                           [popperPlacement]="col.popperPlacement || PopperPlacement.AUTO"
                           (click)="handleArrowTooltipClick(col, element, tooltipContent)">arrow_drop_down</i>

                        <i *ngIf="col.lockedCol && element[col.lockedCol] else editableIcon" class="material-symbols-rounded locked-icon">lock</i>
                        <!-- Editable icon won't show up for col.lockedCol -->
                        <ng-template #editableIcon>
                          <!-- Editable icon -->
                          <i *ngIf="col.editable && element[col.field] && hoveredCol === col && hoveredElement === element" class="material-icons editable edit-icon"
                             (click)="handleHyperLinkClick(col, element)">edit</i>
                        </ng-template>
                      </div>
                      <!-- Sub text col -->
                      <ng-container *ngIf="col.subTextCol">
                        <span class="sub-text-wrapper">
                          <span class="{{ col.subTextStyleClass ? col.subTextStyleClass : '' }} col-sub-text {{ !element[col.subTextLinkEnabledCol] && col.handleSubTextClick ? 'hover-blue-1-color pointer' : '' }}"
                                (click)="col.handleSubTextClick ? col.handleSubTextClick(col, element) : null; subTextRightClickMenuTrigger.closeMenu()"
                                [ngClass]="element[col.subTextLinkEnabledCol] ? 'link-enabled' : ''"
                                [popper]="col.subTextPopperContent"
                                [popperTrigger]="col.popperTrigger || 'hover'"
                                [popperApplyClass]="col.popperApplyClass || null"
                                [popperPlacement]="col.popperPlacement || PopperPlacement.AUTOSTART"
                                [popperHideOnMouseLeave]="col.popperHideOnMouseLeave ? true : false"
                                [popperDisabled]="!col.subTextPopperContent"
                                [popperPositionFixed]="col.popperPositionFixed"
                                [matMenuTriggerFor]="subTextRightClickContextMenu" #subTextRightClickMenuTrigger="matMenuTrigger"
                                (contextmenu)="col.handleSubTextRightClick ? subTextRightClickMenuTrigger.openMenu() : false"
                                oncontextmenu="return false;">

                                <span [innerHTML]="element[col.subTextCol]"></span>
                                <i *ngIf="element[col.subTextInfoIconCol]"
                                   class="material-icons-outlined help-outline sub-text-info-icon"
                                   [popper]="subTextIconContent"
                                   [popperPositionFixed]="true"
                                   popperTrigger="click" [popperApplyClass]="col.popperApplyClass || 'sm'"
                                   (click)="handleSubTextIconTooltipClick(col, element, subTextIconContent)"
                                   [popperPlacement]="col.popperPlacement || PopperPlacement.AUTO">
                                  {{ col.subTextIconSuffix }}
                                </i>
                          </span>

                          <!-- Tooltip Popper (right side caret icon) -->
                        <i *ngIf="col.enableSubTextTooltip && element[col.subTextCol] && !col.subTextInfoIconCol"  class="material-symbols-rounded sub-tooltip-icon"
                           [popper]="subTextTooltipContent" [popperDisabled]="!col.enableSubTextTooltip"
                           [popperPositionFixed]="true"
                           popperTrigger="click" [popperApplyClass]="col.popperApplyClass || 'sm'"
                           [popperPlacement]="col.popperPlacement || PopperPlacement.AUTO"
                           (click)="handleArrowSubTextTooltipClick(col, element, subTextTooltipContent)">expand_more</i>
                        </span>

                        <mat-menu #subTextRightClickContextMenu="matMenu">
                          <button mat-menu-item (click)="col.handleSubTextRightClick(col, element)">Open</button>
                          <button mat-menu-item (click)="col.handleSubTextRightClick(col, element, true)">Open Link in New Tab</button>
                        </mat-menu>
                      </ng-container>

                      <!-- Sub text array col -->
                      <div class="col-sub-text-array" *ngIf="col.subTextArrayCol && element[col.subTextArrayCol]">
                        <ng-container *ngIf="col.subTextViewAllOption else regularSubTextArray">

                          <!-- Show only +more hyperlink -->
                          <p class="text-sm more-val no-wrap" *ngIf="element[col.subTextArrayCol]?.length"
                             (click)="col?.handleSubTextClick(col, element)">
                            View All ({{element[col.subTextArrayCol]?.length}})
                          </p>
                        </ng-container>

                        <!-- Show +more hyperlink with first string object as a prefix text -->
                        <!-- Adding oncontextmenu="return false;" to disable browser right click option. -->
                        <ng-template #regularSubTextArray>
                          <p class="val-1"
                             (click)="onSubTextOptionClick(element, col, element[col.subTextArrayCol][0]); subTextMenuTrigger.closeMenu()"

                             [matMenuTriggerFor]="subTextContextMenu" #subTextMenuTrigger="matMenuTrigger"
                             (contextmenu)="tableConfig.handleSubTextArrayClick ? subTextMenuTrigger.openMenu() : false" oncontextmenu="return false;"

                             [class.pointer]="tableConfig?.handleSubTextArrayClick">{{element[col.subTextArrayCol][0]}}</p>
                          <p class="text-sm more-val no-wrap" *ngIf="element[col.subTextArrayCol]?.length > 1"
                             (click)="showSubTextArray(element, col, element[col.subTextArrayCol], subTextArrayPopper)"
                             [popper]="subTextArrayPopper"
                             popperTrigger="click" popperApplyClass="xs" popperPlacement="auto">
                            +{{element[col.subTextArrayCol]?.length - 1}} more
                          </p>
                        </ng-template>
                      </div>

                      <mat-menu #subTextContextMenu="matMenu">
                        <button mat-menu-item (click)="onSubTextOptionClick(element, col, element[col.subTextArrayCol][0])">Open</button>
                        <button mat-menu-item (click)="onSubTextOptionClick(element, col, element[col.subTextArrayCol][0], true)">Open Link in New Tab</button>
                      </mat-menu>

                      <!-- Sub text array col for empty lists -->
                      <div class="col-sub-text-array" *ngIf="!col.subTextWhiteSpace && col.subTextArrayCol && element[col.subTextArrayCol]?.length == 0">
                        <p class="val-1">-</p>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-container>

              <!-- Skeleton loader STARTS -->
              <div *ngIf="loading && col.type !== 'menu'" class="mt-2">
                <div class="row align-items-center" *ngIf="col.imageCol">
                  <div class="col-md-3">
                    <ngx-skeleton-loader [theme]="{width: '3rem', height: '3rem'}"
                                         class="tbl-skeleton-loader"></ngx-skeleton-loader>
                  </div>
                  <div class="col-md-9 ps-3">
                    <ngx-skeleton-loader [theme]="{width: '7rem', height: '1.1rem'}"
                                         class="tbl-skeleton-loader"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{width: '50%', height: '0.75rem'}"
                                         class="tbl-skeleton-loader"></ngx-skeleton-loader>
                  </div>
                </div>
                <div class=" align-items-center" *ngIf="!col.imageCol">
                  <div class=" p-0">
                    <ngx-skeleton-loader [theme]="{height: '1.1rem'}" class="tbl-skeleton-loader"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <!-- Skeleton loader ENDS -->
              <ng-container
                *ngIf="tableConfig.rowMenuOptions && col.type === 'menu' && (!col.hideMenu || col.hideMenu(col, element))"
                [ngTemplateOutlet]="menuOptionTemp"
                [ngTemplateOutletContext]="{element, dataIndex, col}"></ng-container>
            </td>

            <!-- Footer row td -->
            <ng-container *ngIf="tableConfig?.footerRow">
              <td mat-footer-cell *matFooterCellDef> {{tableConfig.footerRow[col.field]}} </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- Expandable section -->
        <ng-container *ngIf="isExpandable && !loading">
          <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="expand-td">
                <!-- The animation with *ngIf [@inOutAnimation] changes have been made to fix DEV-11323 -->
                <div class="expand-content-wrapper" *ngIf="expandedElement" [@inOutAnimation]
                  [ngClass]="expandedElement ? 'expanded' : 'collapsed'">
                  <ng-content></ng-content>
                </div>
              </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (dblclick)="onRowDoubleClick(row)"
            [class.row-locked]="tableConfig.rowLockingCondition ? tableConfig.rowLockingCondition(row) : false"
            [class.row-clickable]="tableConfig?.onRowClick"
            [class.on-row-selected]="tableConfig?.onRowSelected ? tableConfig?.onRowSelected(row) : null"
            [class.on-row-error]="tableConfig?.onRowError ? tableConfig?.onRowError(row) : null"
            [popper]="tooltipContent"
            [popperDisabled]="!(tableConfig.rowLockingCondition ? tableConfig.rowLockingCondition(row) : false)"
            [popperPositionFixed]="true" [popperApplyClass]="tableConfig?.rowTooltip?.popperApplyClass || 'sm'"
            [popperPlacement]="tableConfig?.rowTooltip?.popperPlacement || PopperPlacement.AUTOSTART" popperTrigger="click"
            (click)="handleRowClick(row)"
            [ngClass]="{'row-selected': selection?.isSelected(row), 'cursor-pointer': tableConfig.onDoubleClick}"
            [cdkDragDisabled]="!tableConfig.isDraggable"
            cdkDrag [cdkDragData]="row"></tr>

        <ng-container *ngIf="isExpandable">
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [style.height]="0"></tr>
        </ng-container>
        <ng-container *ngIf="tableConfig.footerRow">
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </ng-container>
      </table>
    </div>

    <div class="no-data-row-main"
         *ngIf="dataSource.data?.length === 0 && !loading && tableConfig.defaultState && !selectSearchConfig?.inputCtrl?.value && !handleNoDateForAddedFilterChips">
      <div class="no-data-wrapper default-state col-12 col-sm-12 col-md-6 col-lg-5">

        <img *ngIf="!tableConfig.defaultState?.hideJenZen" class="jen-small-img" src="/assets/img/jen/emotion-ratings/happy.svg">

        <h3 class="mb-3" *ngIf="tableConfig.defaultState.title">{{tableConfig.defaultState.title}}</h3>
        <p class="text-md" [innerHTML]="tableConfig.defaultState.message"></p>
        <app-zen-tab-top-action *ngIf="tableConfig.defaultState.actions"
                                [config]="tableConfig.defaultState.actions"></app-zen-tab-top-action>
      </div>
      <div class="wrapper-bg">
        <div class="position-relative">
          <app-zen-skeleton-loader [loaderType]="ZenLoaderTypes.TABLE_BG"></app-zen-skeleton-loader>
        </div>
      </div>
    </div>

    <!-- Default input search and select search - No Results state is handled here -->
    <div class="no-data-row-main" *ngIf="!loading && (handleNoSearchData || handleNoSelectSearchData) else filterNoResults">
      <div class="no-data-wrapper">
        <h3 class="mb-3">No Results</h3>
        <p class="text-md">No results found based on your search criteria.</p>
      </div>
    </div>
    <!-- Filters - No Results state is handled here -->
    <ng-template #filterNoResults>
      <div class="no-data-row-main" *ngIf="!loading && handleNoDateForAddedFilterChips">
        <div class="no-data-wrapper">
          <h3 class="mb-3">No Results</h3>
          <p class="text-md">No results found based on your filter criteria.</p>
        </div>
      </div>
    </ng-template>

    <div class="mat-table-skeleton-loader" *ngIf="dataSource.data?.length === 0 && loading">
      <app-zen-skeleton-loader [loaderType]="ZenLoaderTypes.TABLE"></app-zen-skeleton-loader>
    </div>

  </div>

  <mat-paginator [hidden]="tableConfig.hidePagination" [pageSize]="tableConfig.pageSize || 10" [length]="tableConfig.totalLength"
                 [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)"></mat-paginator>
</div>

<ng-template #menuOptionTemp let-element="element" let-dataIndex="dataIndex" let-col="col">
  <div class="text-center" [matMenuTriggerFor]="appMenu.childMenu"
       [id]="dataIndex === 0 ? col?.tourAnchorId : null"
       (click)="$event.stopPropagation(); onMenuClick.emit(element);">
    <i class="material-icons col-menu-icon">more_horiz</i>
  </div>

  <app-zen-menu-item #appMenu [element]="element" [menuItems]="tableConfig?.rowMenuOptions"></app-zen-menu-item>
</ng-template>

<popper-content #subTextArrayPopper>
  <app-zen-popper [closeable]="true" [popperContent]="subTextArrayPopper">
    <!-- Adding oncontextmenu="return false;" to disable browser right click option. -->
    <div class="popper-scroll">
    <ng-container *ngFor="let val of subTextArrayDetails?.subTextArr">
        <p class="text-md mb-2 sub-text-arr"
           (click)="onSubTextOptionClick(subTextArrayDetails.rowData, subTextArrayDetails.col, val); subTextArrListMenuTrigger.closeMenu()"

           [ngClass]="tableConfig?.handleSubTextArrayClick ? 'pointer hover-blue-1-color' : ''"
           [matMenuTriggerFor]="subTextArrListContextMenu" #subTextArrListMenuTrigger="matMenuTrigger"
           (contextmenu)="tableConfig.handleSubTextArrayClick ? subTextArrListMenuTrigger.openMenu() : false"
           oncontextmenu="return false;" [innerHTML]="val"></p>

        <mat-menu #subTextArrListContextMenu="matMenu">
          <button mat-menu-item (click)="onSubTextOptionClick(subTextArrayDetails.rowData, subTextArrayDetails.col, val)">Open</button>
          <button mat-menu-item (click)="onSubTextOptionClick(subTextArrayDetails.rowData, subTextArrayDetails.col, val, true)">Open Link in New Tab</button>
        </mat-menu>

    </ng-container>
    </div>

  </app-zen-popper>
</popper-content>

<popper-content #headerTooltipTextPopper>
  <div class="bg-dark-transparent">
    <p class="text-md text-start">{{headerTooltipText}}</p>
  </div>
</popper-content>

<popper-content #hoveredTextTooltipContent>
  <div class="p-3">
    <p class="text-md text-color-dark text-start">{{hoveredTooltipText}}</p>
  </div>
</popper-content>
