import {Injectable} from '@angular/core';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {ZenUnitsEnum} from '../../_enums/zen-units.enum';
import {ZenConversionRatesEnum, ZenRatesEnum} from '../../_enums/zen-rates.enum';
import {CommodityFeeFormatPipe} from '../../_pipes/commodity-fee-format.pipe';
import {ZenCurrencyTypeEnum} from '../../_enums/zen-currency-type.enum';
import {TranslateService} from '@ngx-translate/core';
import {CountryEnum, ZenLocaleModel} from '../../_model/zen-locale.model';
import {UnitDto} from '../../_model/unit-model';
import {BillsV4Service} from '../v4/bills-v4.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZenUnitsHelperService {
  translations: ZenLocaleModel;

  constructor(
    private feeFormat: CommodityFeeFormatPipe,
    private translateSvc: TranslateService, private billsV4Svc: BillsV4Service
  ) {
    this.translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
  }



  applyToPlatformMultiplier(unit: ZenUnitsEnum, value: number, allUnits: UnitDto[]) {
    const toPlatformMultiplier = allUnits?.find(u => u.unit === unit)?.toPlatformMultiplier || 1;
    return value * toPlatformMultiplier;
  }

  /** This method used to set min and max validations for supplierRate form control in the ZenContractForm */
  /** Ref: https://www.abraxasenergy.com/energy-resources/toolbox/conversion-calculators/energy/ */
  getMinAndMaxValidationValue(commodity: CommodityType, unit: ZenUnitsEnum, allUnits: UnitDto[]): { min: number; max: number; } {
    const unitInfo = allUnits.find(u => u.unit === unit);
    return {min: unitInfo.minValue, max: unitInfo.maxValue}
  }

  getAllUnitOptions(commodity: CommodityType, isRate = true, allUnits: UnitDto[]): {label: string; value: ZenUnitsEnum; }[] {
    const _symbolPrefix = `${this.translations?.currency?.symbol}/`;
    return allUnits.filter(u => u.commodityType === commodity).map(u => {
      return {label: (isRate ? _symbolPrefix : '') + u.unit, value: u.unit};
    })
  }

  getRateFieldValidationMsg(commodity: CommodityType, unit: ZenUnitsEnum, allUnits: UnitDto[]): string {
    const {min, max} = this.getMinAndMaxValidationValue(commodity, unit, allUnits);
    const currencyType = ZenCurrencyTypeEnum.CURRENCY;
    return `Value must be between ${this.feeFormat.transform(min, {commodity, currencyType})} and ${this.feeFormat.transform(max, {commodity, currencyType})}`;
  }


  /** This method used to calculate rate while changing the supply cost input in the Add Bill -> Calculate Cost step. */
  applyFromPlatformMultiplier(unit: ZenUnitsEnum, rateValue: number, allUnits: UnitDto[]): number {
    if (unit == null) {
      return rateValue;
    }

    const unitInfo = allUnits.find(u => u.unit === unit);
    return rateValue * unitInfo.fromPlatformMultiplier;
  }
}
